import { IqUserDto } from '@/Iq/types';
import { UseRequest, useRequest } from '@/hooks/useRequest';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import i18next from 'i18next';
import { UseBoolean, useBoolean } from '@/hooks/useBoolean';
import { useValueRef } from '@/hooks/useValueRef';
import {
  AnalyticClient,
  redirectToApp,
  RequestMethods,
  RequestStatuses,
  GetUserTradingCoreUrl,
  LoginTradingCoreUrl,
  RegisterTradingCoreUrl,
  RestorePasswordTradingCoreUrl,
  PushNotificationModalStats,
} from '@repo/utils';
import { publicRuntimeConfig } from '@repo/config';

export enum AuthTabsValues {
  Login = 'login',
  Register = 'register',
}

interface MeState {
  fetched: boolean;
  value?: IqUserDto;
}

interface Context {
  meState: MeState;
  tabState: [AuthTabsValues, Dispatch<SetStateAction<AuthTabsValues>>];
  opened: UseBoolean;
  onLogin: (args: { email: string; password: string }) => void;
  onRegister: (args: { email: string; password: string; ad_id: string }) => void;
  onRestorePassword: (args: { email: string }) => void;
  LoginRequest: UseRequest;
  RegisterRequest: UseRequest;
  RestorePasswordRequest: UseRequest;
}

const NEGATIVE_STATUSES = [RequestStatuses.Unauthorized, RequestStatuses.Failed];

const AuthContext = createContext({} as Context);

export const useAuthContext = () => useContext(AuthContext);

const AuthContextProvider = AuthContext.Provider;

interface Props {
  children?: any;
}

export const AuthProvider = (props: Props) => {
  const { children } = props;
  const [meState, setMeState] = useState<MeState>({
    fetched: false,
    value: undefined,
  });
  const userIdRef = useValueRef(meState.value?.traderId);
  const opened = useBoolean(false);
  const tabState = useState<AuthTabsValues>(AuthTabsValues.Register);

  const LoginRequest = useRequest({
    url: LoginTradingCoreUrl,
    method: RequestMethods.Post,
    trackError: false,
  });

  const RegisterRequest = useRequest({
    url: RegisterTradingCoreUrl,
    method: RequestMethods.Post,
    trackError: false,
  });

  const RestorePasswordRequest = useRequest({
    url: RestorePasswordTradingCoreUrl,
    method: RequestMethods.Post,
  });

  const GetUserRequest = useRequest({
    url: GetUserTradingCoreUrl,
    method: RequestMethods.Get,
    trackError: false,
  });

  const log = useCallback((...d: any[]) => {
    if (publicRuntimeConfig.app.log) {
      console.log('[AuthContext]', ...d);
    }
  }, []);

  const handleRegister = useCallback<Context['onRegister']>(({ email, password, ad_id }) => {
    RegisterRequest.onRequest({
      data: { email, password, ad_id },
    });
  }, []);

  const handleLogin = useCallback<Context['onLogin']>(({ email, password }) => {
    LoginRequest.onRequest({
      data: { email, password },
    });
  }, []);

  const handleRestorePassword = useCallback<Context['onRestorePassword']>(({ email }) => {
    RestorePasswordRequest.onRequest({
      data: { email },
    });
  }, []);

  const handleGetUser = useCallback(async () => {
    await GetUserRequest.onRequest({
      data: {},
    });
  }, []);

  const onLocationChanged = useCallback(() => {
    if (Boolean(userIdRef.current) && window.location.pathname === '/') {
      redirectToApp();
    }
  }, []);

  useEffect(() => {
    handleGetUser();
  }, []);

  useEffect(() => {
    const { pushState, replaceState } = window.history;

    window.history.pushState = function (...args) {
      pushState.apply(window.history, args);
      window.dispatchEvent(new Event('pushState'));
    };

    window.history.replaceState = function (...args) {
      replaceState.apply(window.history, args);
      window.dispatchEvent(new Event('replaceState'));
    };

    window.addEventListener('popstate', onLocationChanged);
    window.addEventListener('replaceState', onLocationChanged);
    window.addEventListener('pushState', onLocationChanged);
  }, []);

  useEffect(() => {
    if (meState.fetched && Boolean(meState.value?.traderId) && (window.location.pathname === '/' || opened.value)) {
      redirectToApp();
    }
  }, [meState.fetched, meState.value, opened.value]);

  useEffect(() => {
    if (GetUserRequest.state.status === RequestStatuses.Succeeded) {
      setMeState({
        fetched: true,
        value: GetUserRequest.state.result.payload?.user,
      });
    } else if (NEGATIVE_STATUSES.includes(GetUserRequest.state.status)) {
      setMeState({
        fetched: true,
      });
    }
  }, [GetUserRequest.state.status]);

  useEffect(() => {
    if (NEGATIVE_STATUSES.includes(LoginRequest.state.status)) {
      setMeState({
        fetched: true,
      });
    }
  }, [LoginRequest.state.status]);

  useEffect(() => {
    if (NEGATIVE_STATUSES.includes(RegisterRequest.state.status)) {
      setMeState({
        fetched: true,
      });
    }
  }, [RegisterRequest.state.status]);

  useEffect(() => {
    if (!meState.fetched || !meState.value?.traderId) {
      return;
    }
    PushNotificationModalStats.clear(String(meState.value?.traderId));
  }, [meState.fetched, meState.value?.traderId]);

  useEffect(() => {
    window.AuthModule = window.AuthModule || {};
    window.AuthModule.onOpen = () => {
      opened.onTrue();
    };
    window.AuthModule.onClose = () => {
      opened.onFalse();
    };
    window.AuthModule.onToggle = () => {
      opened.onToggle();
    };
    window.AuthModule.onChangeLanguage = (ln: string) => {
      i18next.changeLanguage(ln);
      AnalyticClient.onChangeLanguage(ln);
    };
  }, []);

  const contextValue = useMemo(
    () => ({
      meState,
      tabState,
      opened,
      onLogin: handleLogin,
      onRegister: handleRegister,
      onRestorePassword: handleRestorePassword,
      onGetUser: handleGetUser,
      LoginRequest,
      RegisterRequest,
      RestorePasswordRequest,
    }),
    [
      meState,
      tabState,
      opened,
      handleLogin,
      handleRegister,
      handleRestorePassword,
      handleGetUser,
      LoginRequest,
      RegisterRequest,
      RestorePasswordRequest,
    ],
  );

  return <AuthContextProvider value={contextValue}>{children}</AuthContextProvider>;
};
