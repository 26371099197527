import React, { useEffect } from 'react';
import { ModalProvider } from '@/context/ModalContext';
import { AuthProvider } from '@/context/AuthContext';
import { Auth } from '@/components/Auth';
import { ModalStack } from '@/components/ModalStack';
import { ConfigProvider } from '@/context/ConfigContext';
import { MuiThemeProvider } from '@/components/MuiThemeProvider';

import { AnalyticClient, AppsflyerClient, OneSignalClient } from '@repo/utils';

import '@/i18n';

interface Props {}

const App: React.FC<Props> = ({}) => {
  useEffect(() => {
    window.AuthModule = window.AuthModule || {};
    window.AuthModule.AnalyticClient = AnalyticClient;
    window.AuthModule.AppsflyerClient = AppsflyerClient;

    AnalyticClient.init({ user: null });

    if (OneSignalClient.isPushApiEnabled() && OneSignalClient.isPushApiSupported()) {
      OneSignalClient.showPushApiPrompt();
    }
  }, []);

  return (
    <MuiThemeProvider>
      <ConfigProvider>
        <ModalProvider>
          <AuthProvider>
            <Auth />
            <ModalStack />
          </AuthProvider>
        </ModalProvider>
      </ConfigProvider>
    </MuiThemeProvider>
  );
};

export default React.memo(App);
